import * as React from 'react';
import InformationModalDialog from '../../../../../../components/others/modal/InformationModalDialog';
import PageForm from '../../../../../_common/components/pages/forms/PageForm';
import ProtectedComponent from '../../../../../../components/authorizations/protectedComponents/ProtectedComponent';
import ProtectedModal from '../../../../../../components/authorizations/protectedComponents/ProtectedModal';
import styles from './styles/LandingsPrelandingsContent.module.scss';
import { ClearBoth } from '../../../../../../components/controls/clearBoth/ClearBoth';
import { getAllProductPages } from '../../../../../../services/m30_products/pages/ProductPagesService';
import { IId } from '../../../../../../common/interfaces/IId';
import { ILanding } from '../../../../../../models/ILanding';
import { IPage } from '../../../../../_common/components/pages/forms/interfaces/IPage';
import { IProductPagesFilters } from '../../../../../../services/m30_products/pages/interfaces/IProductPagesFilters';
import { Left } from '../../../../../../components/controls/left/Left';
import { openSuccessNotification } from '../../../../../../helpers/NotificationHelper';
import { PagesTable } from '../tables/PagesTable';
import { RefreshTableButton } from '../../../../../../components/refreshTableButton/RefreshTableButton';
import { resources } from '../../../../../../common/Resources';
import { Right } from '../../../../../../components/controls/right/Right';
import { UserPermissionType } from '../../../../../../models/IAppUserInfoModel';

export class ProductPagesContent extends React.Component<IId> {
    state = {
      pagesList: Array<ILanding>(),
      loading: false,
    }

    componentDidMount() {
      this.getPages();
    }

    render() {
      return (
        <>
          <Left>
            <RefreshTableButton
              className='marginBottom marginRigth'
              loading={this.state.loading}
              onClick={this.getPages}
            />
          </Left>
          <Right>
            <ProtectedModal permission={UserPermissionType.AddNewPageInProductsLandinggsModalButton}>
              <InformationModalDialog
                icon={"form"}
                buttonType='primary'
                buttonTitle={'Add new'}
                modalTitle={'Add new page'}
                disabled={this.state.loading}
                withHideMethod={true}
              >
                <PageForm
                  product_id={Number(this.props.id)}
                  addNewPage={this.addNewPage}
                  is_prelanding={1}
                />
              </InformationModalDialog>
            </ProtectedModal>
          </Right>
          <ProtectedComponent permission={UserPermissionType.SeePrelandingsTable}>
            <Left>
              <h2>Prelandings</h2>
            </Left>
            <ClearBoth />
            <PagesTable
              loading={this.state.loading}
              tableName="prelandings"
              productId={this.props.id}
              data={this.state.pagesList.filter((x: any) => (x.is_prelanding === 1))}
              type="prelandings"
              updatePage={this.updatePage}
            />
          </ProtectedComponent>

          <ProtectedComponent permission={UserPermissionType.SeeLandingsTable}>
            <div className={styles.sectionDivider}></div>
            <Left>
              <RefreshTableButton
                className='marginBottom marginRigth'
                loading={this.state.loading}
                onClick={this.getPages}
              />
            </Left>
            <Right>
              <ProtectedModal permission={UserPermissionType.AddNewPageInProductsLandinggsModalButton}>
                <InformationModalDialog
                  icon={"form"}
                  buttonType='primary'
                  buttonTitle={'Add new'}
                  modalTitle={'Add new page'}
                  disabled={this.state.loading}
                  withHideMethod={true}
                >
                  <PageForm
                    product_id={Number(this.props.id)}
                    addNewPage={this.addNewPage}
                  />
                </InformationModalDialog>
              </ProtectedModal>
            </Right>
            <Left>
              <h2>Landings</h2>
            </Left>
            <ClearBoth />
            <PagesTable
              loading={this.state.loading}
              tableName="landings"
              productId={this.props.id}
              data={this.state.pagesList.filter((x: any) => (x.is_prelanding === 0))}
              type="landings"
              updatePage={this.updatePage}
            />
          </ProtectedComponent>
        </>
      );
    }

    getPages = () => {
      this.setState({ loading: true });

      const object: IProductPagesFilters = {
        columns: [
          "id",
          "name",
          "url",
          "is_prelanding",
          "is_mobile",
          "is_desktop",
          "is_for_influencers",
          "is_top",
          "created_at",
          "deleted_at",
          "is_visible"
        ]
      };

      getAllProductPages(Number(this.props.id), object)
        .then(response => {
          const pages = response.map((item: any) => { return { ...item, key: item.id } });

          this.setState({ pagesList: pages });
        })
        .finally(() => { this.setState({ loading: false, }) });
    }

    addNewPage = (page: IPage) => {
      const pagesList = [...this.state.pagesList, page];

      this.setState({ pagesList });

      openSuccessNotification(resources.statement.created);
    }

    updatePage = (page: IPage) => {
      const { pagesList } = this.state;

      const index = pagesList.findIndex(x => x.id === page.id);

      pagesList[index] = page;

      this.setState({ pagesList });

      openSuccessNotification(resources.statement.updated);
    }
}
