import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getPayoutsHistory,
  getActualProxyPayouts,
  getFutureProxyPayouts,
} from "../../../../services/payouts/PayoutsServices";

export const PayoutSettings = () => {
  const { id } = useParams<{ id: string }>();
  const userId = useSelector((state: any) => state.commonState.appUserInfo.id);
  const { data } = useQuery("payouts-history", () =>
    getPayoutsHistory(userId, parseInt(id))
  );
  const proxyFuture = useQuery("payouts-proxy-future", () =>
    getFutureProxyPayouts(userId, id as any)
  );
  const proxyActual = useQuery("payouts-proxy-actual", () =>
    getActualProxyPayouts(userId, id as any)
  );

  return (
    <div style={{ marginTop: "15px" }}>
      {proxyActual.data && proxyActual.data.id && (
        <span
          style={{ fontSize: "18px", display: "block", marginBottom: "10px" }}
        >
          Actual value for this product is{" "}
          {proxyActual.data.payout_scheme.toUpperCase()},{" "}
          {proxyActual.data.payout_cpa / 100 ||
            proxyActual.data.payout_cpl / 100}{" "}
          USD
        </span>
      )}

      {proxyFuture.data && proxyFuture.data.id && !proxyFuture.data.is_handled && (
        <span
          style={{
            fontSize: "18px",
            display: "block",
            marginBottom: "10px",
          }}
        >
          The payout will be changed into{" "}
          {proxyFuture.data.payout_scheme.toUpperCase()}{" "}
          {proxyFuture.data.payout_cpa / 100 ||
            proxyFuture.data.payout_cpl / 100}{" "}
          USD at{" "}
          {proxyFuture.data.started_at
            ? `${moment(proxyFuture.data.started_at)
                .utc()
                .format("DD-MM-YYYY HH:mm")} UTC${proxyFuture.data.timezone}`
            : ""}
        </span>
      )}

      <span style={{ fontSize: "18px", display: "block", marginBottom: "5px" }}>
        Payout history for this product
      </span>

      {data && data.items.length > 0 ? (
        <table>
          <tbody>
            {data.items.map((item: any) => (
              <tr key={item.id}>
                <td style={{ paddingRight: "10px" }}>
                  {moment(item.started_at).utc().format("DD-MM-YYYY HH:mm")}
                </td>
                <td style={{ padding: "0 10px" }}>
                  {item.payout_scheme.toUpperCase()}
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  {item.payout_value / 100} USD
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span>No history items for this product</span>
      )}
    </div>
  );
};
